<template>
  <div>
    <!-- <topHeader :userData="user" /> -->
    <Navbar :userData="user" />
    <div class="mapbg">
      <div class="container-fluid">
        <div class="__container">
          <div  class="__headflex">
            <div class="__hdimg">
                <div class="mapbg__ripplect">
                <img src="@/assets/img/people.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>

              <div class="mapbg__ripplect-1">
                <img src="@/assets/img/people1.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>

              <div class="mapbg__ripplect-2">
                <img src="@/assets/img/people2.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>
            </div>
            <div class="__hdimgmd">
                <div class="mapbg__above">
                <p class="__moveinleft mapbg__above-find text-center">
                  Book experts for different digital and professional services across Africa
                </p>
                <!-- <div class="form-group">
                <input
                    type="text"
                    class="form-control __moveup mapbg__above-dasminp"
                    placeholder="Hire an expert"
                    v-model="searchExperts"
                    @keyup="handleProfileSearch($event)"
                />
                </div> -->

                <div class="input-group __moveup mapbg__above-dismform">
                    <input 
                        type="text" 
                        class="form-control  mapbg__above-dasminp" 
                        placeholder="Search for professional and companies" 
                        aria-describedby="basic-addon2"
                        v-model="searchExperts"
                        @keyup="handleProfileSearch($event)"
                    >
                    <span class="input-group-addon" id="basic-addon2">Get Started</span>
                </div>
              </div>

              <div class="mapbg__ripplect-3">
                <img src="../assets/img/people3.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple __lit"></span>
                <span class="mapbg__ripplect-ripple __lit"></span>
                <span class="mapbg__ripplect-ripple __lit"></span>
              </div>

              <div class="mapbg__ripplect-4">
                <img src="../assets/img/people5.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple __lit"></span>
                <span class="mapbg__ripplect-ripple __lit"></span>
                <span class="mapbg__ripplect-ripple __lit"></span>
              </div>
            </div>
            <div class="__hdimg">
                <div class="mapbg__ripplect-5">
                <img src="../assets/img/people4.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>

              <div class="mapbg__ripplect-6">
                <img src="../assets/img/people2.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>

              <div class="mapbg__ripplect-7">
                <img src="../assets/img/people5.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>
            </div>
          </div>
          <!-- <b-row>
            <b-col xl="3" lg="3" md="2" sm="2">
              <div class="mapbg__ripplect">
                <img src="../assets/img/people.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>

              <div class="mapbg__ripplect-1">
                <img src="../assets/img/people1.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>

              <div class="mapbg__ripplect-2">
                <img src="../assets/img/people2.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>
            </b-col>
            <b-col xl="6" lg="6" md="8" sm="8">
              <div class="mapbg__above">
                <p class="__moveinleft mapbg__above-find text-center">
                  Book experts for different digital and professional services across Africa
                </p>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control __moveup mapbg__above-dasminp"
                      placeholder="Hire an expert"
                      v-model="searchExperts"
                      @keyup="handleProfileSearch($event)"
                    />
                  </div>
              </div>

              <div class="mapbg__ripplect-3">
                <img src="../assets/img/people3.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple __lit"></span>
                <span class="mapbg__ripplect-ripple __lit"></span>
                <span class="mapbg__ripplect-ripple __lit"></span>
              </div>

              <div class="mapbg__ripplect-4">
                <img src="../assets/img/people5.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple __lit"></span>
                <span class="mapbg__ripplect-ripple __lit"></span>
                <span class="mapbg__ripplect-ripple __lit"></span>
              </div>
            </b-col>
            <b-col xl="3" lg="3" md="2" sm="2">
              <div class="mapbg__ripplect-5">
                <img src="../assets/img/people4.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>

              <div class="mapbg__ripplect-6">
                <img src="../assets/img/people2.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>

              <div class="mapbg__ripplect-7">
                <img src="../assets/img/people5.png" class="mapbg-peopleimg" />
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
                <span class="mapbg__ripplect-ripple"></span>
              </div>
            </b-col>
          </b-row> -->
        </div>
      </div>
    </div>

    <section class="professional">
      <b-container>
        <b-row>
          <b-col cols="12" xl="9" lg="9" md="8" sm="8">
            <p class="professional__meet">
              Discover verified professionals on Bookme today.
            </p>
          </b-col>
          <b-col
            cols="12"
            xl="3"
            lg="3"
            md="4"
            sm="4"
            class="professional__shifr"
          >
            <router-link to="/explore" class="btn professional__links"
              >Explore all</router-link
            >
          </b-col>
        </b-row>

        <div class="customer-logos slider __ldsld">
          <div
            class="slide __slldin"
            v-for="profile of this.interestingProfiles"
            :key="profile.id"
          >
          <a href="#" @click.prevent="handleGotoUserProfile(profile.slug)">
            <div
              class="__owl-carousel_card"
              :style="{
                'background-image': `linear-gradient( 358.23deg, #000000 3.46%, rgba(0, 0, 0, 0) 46.91%), url(${profile.avatar})`,
              }"
            >
              <p class="__owl-carousel_card-name">{{ profile.fullName }}</p>
            </div>
          </a>
          </div>
        </div>

        <div class="professional__mgtp">
          <b-row>
            <b-col cols="12" xl="9" lg="9" md="" sm="8">
              <p class="professional__meet">Featured Profiles</p>
            </b-col>
            <b-col
              cols="12"
              xl="3"
              lg="3"
              md="4"
              sm="4"
              class="professional__shifr"
            >
              <router-link to="/explore" class="btn professional__links"
                >Explore all</router-link
              >
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col cols="12" xl="5" lg="5" md="5" sm="5" v-if="interestingProfiles[0]">
            <a href="#" @click.prevent="handleGotoUserProfile(interestingProfiles[0].slug)">
            <div
              class="professional__owl-carousel_card-fcard1"
              :class="!this.interestingProfiles[0].avatar ? '__pfcardimg1':''"
              :style="{'background-image': this.interestingProfiles[0].avatar ? `linear-gradient( 358.23deg, #000000 3.46%, rgba(0, 0, 0, 0) 46.91%), url(${this.interestingProfiles[0].avatar})`: ''}"
            >
              <p class="professional__owl-carousel_card-name">{{ this.interestingProfiles[0].fullName}}</p>
              <p class="professional__owl-carousel_card-name-f1">
                Open for mentorship & Mentorship
              </p>
            </div>
            </a>
          </b-col>
          <b-col cols="12" xl="7" lg="7" md="7" sm="7" v-if="interestingProfiles[1]">
            <a href="#" @click.prevent="handleGotoUserProfile(interestingProfiles[1].slug)">
            <div 
              class="professional__owl-carousel_card-fcard1"
              :class="!this.interestingProfiles[1].avatar ? '__pfcardimg2':''"
              :style="{'background-image': this.interestingProfiles[1].avatar ? `linear-gradient( 358.23deg, #000000 3.46%, rgba(0, 0, 0, 0) 46.91%), url(${this.interestingProfiles[1].avatar})`: ''}"
            >
              <p class="professional__owl-carousel_card-name">{{ this.interestingProfiles[1].fullName}}</p>
              <p class="professional__owl-carousel_card-name-f1">
                Open for mentorship & Mentorship
              </p>
            </div>
            </a>
            <b-row>
              <b-col cols="12" xl="9" lg="9" md="10" sm="10">
                <p class="professional__cont">
                  Meet expert from different industries and companies on BookMe. Enjoy the professional experience with fellow brilliant  minds today.
                </p>

                <router-link to="" class="professional__getlink"
                  >Get Featured now <img src="../assets/img/link-arrow.png"
                /></router-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="service">
      <b-container>
        <b-row>
          <b-col cols="12" xl="9" lg="9" md="8" sm="8">
            <p class="service__meet">Join Bookme to access</p>
          </b-col>
          <b-col cols="12" xl="3" lg="3" md="4" sm="4" class="service__shifr">
            <router-link to="/explore" class="btn service__links"
              >Explore all</router-link
            >
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="service__service-card __serviceimg">
              <b-row>
                <b-col cols="12" xl="8" lg="8" md="12" sm="12">
                  <p class="service__profp">Professional Services</p>
                </b-col>
                <b-col cols="12" xl="4" lg="4" md="12" sm="12">
                  <router-link to="/explore" class="btn service__expl"
                    >Explore all</router-link
                  >
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="service__service-card __serviceimg1">
              <b-row>
                <b-col cols="12" xl="8" lg="8" md="12" sm="12">
                  <p class="service__profp">Mentorship</p>
                </b-col>
                <b-col cols="12" xl="4" lg="4" md="12" sm="12">
                  <router-link to="/explore" class="btn service__expl"
                    >Explore all</router-link
                  >
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="service__service-card __serviceimg2">
              <b-row>
                <b-col cols="12" xl="8" lg="8" md="12" sm="12">
                  <p class="service__profp">Book Experts & Events</p>
                </b-col>
                <b-col cols="12" xl="4" lg="4" md="12" sm="12">
                  <router-link to="/explore" class="btn service__expl"
                    >Explore all</router-link
                  >
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="service__service-card __serviceimg3">
              <b-row>
                <b-col cols="12" xl="8" lg="8" md="12" sm="12">
                  <p class="service__profp">Find Opportunities</p>
                </b-col>
                <b-col cols="12" xl="4" lg="4" md="12" sm="12">
                  <router-link to="/explore" class="btn service__expl"
                    >Explore all</router-link
                  >
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <topFooter />
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import { mapActions, mapGetters } from "vuex";

// import topHeader from "../components/topHeader.vue";
import Navbar from "../components/Navbar.vue";
import topFooter from "../components/topFooter";

import carouselSlider from "../components/carouselSlider.vue";
import carouselSlide from "../components/carouselSlide.vue";
import UserMixin from "../mixins/usersMixins";
import { errorHandler } from "../../utils/helpers";

export default {
  name: "Home",
  mixins: [UserMixin],
  components: {
    carousel,
    carouselSlider,
    carouselSlide,
    Navbar,
    // topHeader,
    topFooter
  },
  computed: mapGetters([
    "user"
  ]),
  data() {
    return {
      sliders: [
        {
          name: "Lovely Ajayi",
          product: "Product Designer",
          location: "Lagos Nigeria",
          className: "__cardimg",
        },

        {
          name: "John Manalaysay",
          product: "Product Designer",
          location: "Lagos Nigeria",
          className: "__cardimg4",
        },
        {
          name: "Pranava Liz",
          product: "Model",
          location: "Texas USA",
          className: "__cardimg2",
        },
        {
          name: "Lovely Ajayi",
          product: "Product Designer",
          location: "Lagos Nigeria",
          className: "__cardimg3",
        },
        {
          name: "Pranava Liz",
          product: "Model",
          location: "Texas USA",
          className: "__cardimg1",
        },
        {
          name: "Pranava Liz",
          product: "Model",
          location: "Texas USA",
          className: "__cardimg1",
        },
      ],

      sliderms: [
        {
          name: "XDH Companies",
          product:
            "Discover best comapnies with best rating across Europe, the Middle East and Africa.",
          className: "__fccardimg1",
        },

        {
          name: "XDH Companies",
          product:
            "Discover best comapnies with best rating across Europe, the Middle East and Africa.",
          className: "__fccardimg2",
        },
        {
          name: "XDH Companies",
          product:
            "Discover best comapnies with best rating across Europe, the Middle East and Africa.",
          className: "__fccardimg1",
        },
      ],
      searchExperts: '',
      interestingProfiles: []
    };
  },
  methods: {
    ...mapActions(["handleSearch"]),
    handleProfileSearch(e) {
      e.preventDefault();
      if(e.code.toUpperCase() === "ENTER") this.$router.push(`/explore?search=${this.searchExperts}`);
      localStorage.removeItem('selectedExploreTab');
    },

    handleGotoUserProfile(slug) {
      this.$router.push(`/${slug}`);
    }
  },
  async created() {
    try {
      const { data: users } = await this.handleSearch(
        `term=${""}&filter=${""}&values=${""}`
      );

      this.interestingProfiles = users.slice(0, 10);
    } catch (error) {
      errorHandler(error, this.$toast);
    }
  }
};
</script>

<style lang="scss">
@import "../assets/css/home";
@import "../assets/css/carousel";
</style>