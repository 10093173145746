<template>
    <div>
        <div class="professional__owl-carousel_card" :style="{
            'background-image': `linear-gradient( 358.23deg, #000000 3.46%, rgba(0, 0, 0, 0) 46.91%), url(${item.avatar})`,
            width: '30%',
            display: 'inline-block'
        }">
            <p class="professional__owl-carousel_card-name">{{ item.fullName }}</p>
            <!-- <b-row>
                <b-col cols="2" lg="2" md="2" sm="2" class="col-xs-2"><img src="../assets/img/card-portfolio.png" class="professional__owl-carousel_card-img"></b-col>
                <b-col cols="10" lg="10" md="10" sm="10" class="col-xs-10"><p class="professional__owl-carousel_card-name1"> {{ '' }}</p></b-col>
            </b-row> -->
<!--             
            <b-row>
                <b-col cols="2" lg="2" md="2" sm="2" class="col-xs-2"><img src="../assets/img/placeholder.png" class="professional__owl-carousel_card-img"></b-col>
                <b-col cols="10" lg="10" md="10" sm="10" class="col-xs-10"><p class="professional__owl-carousel_card-name1"> {{ '' }}</p></b-col>
            </b-row> -->
        </div>
    </div>
</template>

<script>
    export default {
        name: 'carouselSlider',
        props: ['item']
    }
</script>

<style>
    
</style>