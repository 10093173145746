<template>
  <footer class="__foter">
    <div class="container">
      <div class="__fotflx">
        <div class="__fotimg">
          <img src="../assets/img/logow.png"/> |
          <img src="../assets/img/findworka-logo.png"/>
          <br><br>
          <p class="__fotr" style="line-height:1.5">Book experts for different digital <br>and professional services across Africa</p>
          
        </div>
        <div class="__fotxf">
            <div class="__fotxflx">
                <div>
                    <p class="__fh">Company</p>
                    <p><a href="" class="__fotr">Mentorship</a></p>
                    <p><a href="" class="__fotr">Companies</a></p>
                    <p><a href="" class="__fotr"> Pricing </a></p>
                    <p><a href="" class="__fotr"> Hiring </a></p>
                </div>
                <div>
                    <p class="__fh">Talents</p>
                    <p><a href="" class="__fotr">Pro platform</a></p>
                    <p><a href="" class="__fotr"> Findworka academy</a></p>
                    <p><a href="" class="__fotr"> Saucecode </a></p>
                </div>
                <div>
                    <p class="__fh">Information</p>
                    <p><a href="" class="__fotr">Terms & Service </a></p>
                    <p><a href="" class="__fotr">Privacy policy </a></p>
                    <p><a href="" class="__fotr"> FAQ </a></p>
                </div>
            </div>
            <p class="__fh __litcng">Contact</p>
            <p class="__fotrb">(+234) 817 161 1434</p>
            <p class="__fotrb">
                <a href="mailto:info@findworka.com">info@findworka.com</a>
            </p>
            <p class="__fotrb">1A, Hughes Ave. Alagomeji, Yaba, Lagos</p>
            <div class="__footimg">
                <a href="https://www.instagram.com/findworka/"><img src="../assets/img/instagram.png" /></a>
                <a href="https://mobile.twitter.com/findworka"><img src="../assets/img/twitter.png" /></a>
                <a href="https://www.linkedin.com/mwlite/company/findworka"><img src="../assets/img/linkedin.png" /></a>
                <a href="https://m.facebook.com/findworka"><img src="../assets/img/facebook.png" /></a>
            </div>
            
        </div>
      </div>
      
      <!-- <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6">
          <p class="__fh">Company</p>
          <p><a href="" class="__fotr">Mentorship</a></p>
          <p><a href="" class="__fotr">Companies</a></p>
          <p><a href="" class="__fotr"> Pricing </a></p>
          <p><a href="" class="__fotr"> Hiring </a></p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6">
          <p class="__fh">Talents</p>
          <p><a href="" class="__fotr">Pro platform</a></p>
          <p><a href="" class="__fotr"> Findworka academy</a></p>
          <p><a href="" class="__fotr"> Saucecode </a></p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <p class="__fh">Information</p>
          <p><a href="" class="__fotr">Terms & Service </a></p>
          <p><a href="" class="__fotr">Privacy policy </a></p>
          <p><a href="" class="__fotr"> FAQ </a></p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
          <p class="__fh">Contact</p>
          <p class="__fotrb">(+234) 817 161 1434</p>
          <p class="__fotrb">
            <a href="mailto:info@findworka.com">info@findworka.com</a>
          </p>
          <p class="__fotrb">1A, Hughes Ave. Alagomeji, Yaba, Lagos</p>
          <div class="__footimg">
            <a href="https://www.instagram.com/findworka/"><img src="../assets/img/instagram.png" /></a>
            <a href="https://mobile.twitter.com/findworka"><img src="../assets/img/twitter.png" /></a>
            <a href="https://www.linkedin.com/mwlite/company/findworka"><img src="../assets/img/linkedin.png" /></a>
            <a href="https://m.facebook.com/findworka"><img src="../assets/img/facebook.png" /></a>
          </div>
        </div>
      </div> -->
      <hr class="__fothr">
      <div class="__lstflx">
          <p class="__fotpr">BookMe is a product of Findworka Solutions Limited</p>
          <p class="__coprit">© {{ new Date().getFullYear() }} BookMe</p>
      </div>
      
    </div>
  </footer>
</template>

<script>
export default {
  name: "TopFooter"
};
</script>

<script>document.write(new Date().getFullYear())</script>

<style lang="scss">
</style>
