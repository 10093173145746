<template>
  <header class="__newnv">
    <nav class="navbar __navbar" v-bind:class="{color: navBarColor}"  role="navigation">
      <div class="container">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle nav-button"
            data-toggle="collapse"
            data-target=".navbar-ex1-collapse"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="line1 icon-bar"></span>
            <span class="line2  "></span>
            <span class="line3 icon-bar"></span>
          </button>
          <router-link to="/" class="navbar-brand">
            <img src="../assets/img/logow.png" class="__logoimg" />
          </router-link>
        </div>

        <div class="collapse navbar-collapse navbar-ex1-collapse">
          <ul class="nav navbar-nav navbar-right">
            <li class="__navlink">
              <router-link to="/explore" class="">Explore</router-link>
            </li>
            <li class="dropdown __dropdown" v-if="user && !haveBanner">
              <a
                href="#"
                class="dropdown-toggle __dropdown-droptoggle"
                data-toggle="dropdown"
              >
                {{ user.fullName ? user.fullName.split(" ")[0]: user.company.name.split(" ")[0] }}
                <template v-if="user.type !== 'talent'">
                  <img
                    :src="user.company.logo ? `${ user.company.logo}` : require('../assets/img/default-img.jpg')"
                    class="__navbar__dropimg"
                  />
                </template>
                <template v-if="user.type === 'talent'">
                  <img
                    :src="user.avatar ? `${user.avatar}` : require('../assets/img/default-img.jpg')"
                    class="__navbar__dropimg"
                  />
                </template>
              </a>
              <ul class="dropdown-menu __dropdown__dropdown-menu">
                <div class="row">
                  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <template v-if="user.type !== 'talent'">
                      <img
                        :src="user.company.logo ? `${ user.company.logo}` : require('../assets/img/default-img.jpg')"
                        class="__navbar__dropimg"
                      />
                    </template>
                    <template v-if="user.type === 'talent'">
                      <img
                        :src="user.avatar ? `${ user.avatar}` : require('../assets/img/default-img.jpg')"
                        class="__navbar__dropimg"
                      />
                    </template>
                  </div>
                  <div
                    class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                  >
                    <p class="__dropdown_p" v-if="user.fullName">
                      {{ user.fullName.split(" ")[0] }}
                    </p>
                    <p class="__dropdown_p" v-if="user.company && user.company.name">
                      {{ user.company.name.split(" ")[0] }}
                    </p>
                    <template v-if="user.type !== 'talent' && user.company">
                      <router-link :to="`/${user.company.slug}/company-profile-edit`" class="btn __dropdown_editbtn">
                        Edit Profile
                      </router-link>
                      <router-link :to="`/${user.company.slug}/company-profile`" class="btn __dropdown_viewbtn">
                        View Profile
                      </router-link>
                    </template>
                    <template v-if="user.type === 'talent'">
                      <router-link :to="`/${user.slug}/profile-edit`" class="btn __dropdown_editbtn">
                        Edit Profile
                      </router-link>
                      <router-link :to="`/${user.slug}`" class="btn __dropdown_viewbtn">
                        View Profile
                      </router-link>
                    </template>
                  </div>
                </div>
                <hr />
                <!-- <a href="" class="__dropdown_savp"
                  ><img
                    src="../assets/img/messageimg.png"
                    class="__dropdown_iconimg"
                  />&nbsp;&nbsp; My Messages</a
                > -->
                <!-- <hr />
                <a href="" class="__dropdown_savp"
                  ><img
                    src="../assets/img/inperson.png"
                    class="__dropdown_iconimg"
                  />&nbsp;&nbsp; Saved Profile</a
                > -->
                <!-- <hr />
                <a href="" class="__dropdown_savp"
                  ><img
                    src="../assets/img/folder.png"
                    class="__dropdown_iconimg"
                  />&nbsp;&nbsp; Account</a
                > -->
                <!-- <template v-if="user && user.type !== 'company'">
                  <div class="row">
                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9">
                      <p class="__dropdown_savp">
                        <img
                          src="../assets/img/briefcase.png"
                          class="__dropdown_iconimg"
                        />&nbsp;&nbsp; Available for Work
                      </p>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <input type="checkbox" class="switch_1" :checked="user && +user.profile.mentorship_available === 1" disabled s/>
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9">
                      <p class="__dropdown_savp">
                        <img
                          src="../assets/img/briefcase.png"
                          class="__dropdown_iconimg"
                        />&nbsp;&nbsp; Not available for Mentorship
                      </p>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
                      <input type="checkbox" class="switch_1" :checked="user && +user.profile.mentorship_available === 1" disabled/>
                    </div>
                  </div>
                </template> -->
                <a
                  href="#"
                  class="__dropdown_savp"
                  @click.prevent="handleLogout"
                  style="display: inline-block; marginTop: 24px"
                >
                  <img
                    src="../assets/img/logoutimg.png"
                    class="__dropdown_iconimg"
                  />&nbsp;&nbsp; 
                    Log out
                </a>
              </ul>
            </li>
            <li class="" v-if="!user">
              <router-link
                to="/login"
                v-if="!user"
                class="btn __navlinkbtn1"
              >
                Get Started
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "TopHeader",
  props: {
    haveBanner: {
      type: Boolean,
      default: false
    },
    userData: {
      type: Object,
      require: false
    }
  },
  watch: {
    userData(next, _) {
      this.user = next;
    }
  },
  data() {
    return {
      user: null
    }
  },

  mounted() {
      $(document).ready(function() {
  
        $('.nav-button').click(function() {
            $('.nav-button').toggleClass('change');
        })
            
    });
  },
  methods: {
    ...mapActions([
      "logoutUser"
    ]),
    async handleLogout() {
      await this.logoutUser();
      location.pathname = "/login";
    },
  },
  async created() {
    this.user = this.userData;
  },

  computed: {
      navBarColor() {
        return this.$route.meta.navBarColor
      }
   },
};
</script>
<style lang="scss">
#nav a.router-link-exact-active {
  color: #ffffff;
}
</style>
