<template>
    <div>
        <div class="professional__owl-carousel_card-1" :class="itemS.className">
            <b-row>
                <b-col col="12" lg="7" md="10" sm="10">
                    <p class="professional__owl-carousel_card-name">{{ itemS.name }}</p>
                    <p class="professional__owl-carousel_card-name-f1"> {{ itemS.product }}</p>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'carouselSlide',
        props: ['itemS'],
        // computed: {
        //     img() {
        //     return `./images/cameras/${encodeURIComponent(this.name)}.jpg`;
        //     }
        // }
    }
</script>

<style>
    
</style>